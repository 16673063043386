<template>
  <v-row>
    <v-col cols="12" md="4">
      <h5 class="grey100--text text-body-2" v-text="'Provider'" />
      <p class="mb-0 text--secondary lh-1-2 text-capitalize text-caption" v-text="transaction.data.provider" />
    </v-col>
    <v-col v-if="transaction.status === 1" cols="12" md="4">
      <h5 v-t="'common.amount'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2">
        {{ transaction.data.amount | toScrap }} scrap
      </p>
    </v-col>
    <v-col v-if="transaction.status === 1" cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.bonus'" class="grey100--text text-body-2" />
      <p class="mb-0 lh-1-4 text-body-2">
        <span v-if="bonus.value" class="green400--text">
          +{{ bonus.value | toScrap }} ({{ bonus.amount * 100 | toLocale }}%)
        </span>
        <span v-else class="text--disabled">&mdash;</span>
      </p>
    </v-col>
    <v-col cols="12">
      <h5 class="grey100--text text-body-2">
        Order URL
      </h5>
      <a
        :href="transaction.data.url"
        target="_blank" rel="noopener noreferrer"
        class="link blue400--text lh-1-4 text-caption"
      >
        <fai :icon="['fad', 'external-link-alt']" class="mr-2" />
        <span v-text="transaction.data.url" />
      </a>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    bonus() {
      return {
        amount: this.transaction.data?.bonus ?? 0,
        value: this.transaction.data?.bonusValue ?? 0,
      }
    },
  },
}
</script>
